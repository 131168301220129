import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import {
  CtaArrow,
  Rotations,
  Sizes
} from "../../../../../components/general/CtaArrow/CtaArrow"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { RevealBlockAnimation } from "../../../../../components/general/RevealBlockAnimation/RevealBlockAnimation"
import Section from "../../../../../components/general/Section"
import {
  SectionInfoLine,
  TitleH1
} from "../../../../../components/general/Typography/Typography"
import TypographyAnimation from "../../../../../components/general/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import {
  ContainerElement,
  CtaArrowContainerElement,

  SpacingContainerElement, TitleContainer
} from "./styled"

export const Hero: React.FC = () => {
  const imageData = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "sol-tZw3fcjUIpM-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <ContainerElement id="home-hero">
        <Grid
          gridElementProps={{
            baseHeight: "calc(100vh - 180px)",
            lineColor: "rgba(0,0,0,.1)",
            color: GrayGridTheme.color,
            spaced: true,
            noOverFlowHidden: true,
            backgroundImage: `url(${imageData.heroImage.childImageSharp.fluid.src}) no-repeat center center/cover`,
          }}
        >
          <Section>
            <TitleContainer>
              <RevealBlockAnimation background={Color.Black}>
                <TitleH1 color={Color.Black}>
                  Automatisering & software voor de industrie
                </TitleH1>
              </RevealBlockAnimation>
            </TitleContainer>
            <TypographyAnimation>
              <SectionInfoLine color={Color.DarkGray}>
                SINDS 1996
              </SectionInfoLine>
            </TypographyAnimation>
            <CtaArrowContainerElement>
              <CtaArrow
                color={Color.Black}
                size={Sizes.large}
                rotation={Rotations.down}
              />
            </CtaArrowContainerElement>
          </Section>
          <Section />
        </Grid>
      </ContainerElement>
      <SpacingContainerElement>
      <Grid
          gridElementProps={{
            baseHeight: "250px",
            ...GrayGridTheme,
          }}
        ></Grid>
      </SpacingContainerElement>
    </>
  )
}
