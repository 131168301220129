import styled from "styled-components/macro"

export const ContainerElement = styled.div`
  display: flex;
`

export const TitleContainerElement = styled.div`
  position: absolute;
  transform: rotate(90deg);
  z-index: 1;
  left: -80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -75px;
  pointer-events: none;
  @media (max-width: 768px) {
    display: none;
  }
`

export const PageContainerButtonElement = styled.div`
  margin-bottom: 50px;
  @media (max-width: 768px) {
    padding-bottom: 15px;
    border-bottom: 1px solid black;
    margin-bottom: 30px;
  }
`

export const MobileInfoContainer = styled.div`
  margin-bottom: 50px;
  /* padding: 50px; */
  @media (min-width: 769px) {
    display: none;
  }
`

export const ButtonContainerElement = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`
