import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import {
  Color,
  FontFamily,
  FontWeight,
} from "../../../../../data/style/variables"
import {
  TitleQuote,
  Typography,
  TypographyTypes,
} from "../../../../general/Typography/Typography"
import { ContainerElement, TitleContainerElement } from "./styled"

export const Airflow: React.FC = () => {
  const imageData = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "shutterstock_782843011.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "900px",
          lineColor: "rgba(0,0,0,.1)",
          background: Color.White,
          spaced: true,
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), #0000009e), url(${imageData.heroImage.childImageSharp.fluid.src}) no-repeat center center/cover`,
          detailTextColor: Color.LightGray,
          noOverFlowHidden: true,
        }}
      >
        <Section sectionElementProps={{ justifyCenter: true }}>
          <TitleContainerElement>
            <Typography
              titleType={TypographyTypes.p}
              fontWeight={FontWeight.Light}
              fontFamily={FontFamily.RobotoMono}
              fontSize={"2.4rem"}
              color={Color.White}
            >
              // We houden van een technische uitdaging
            </Typography>
            <TypographyAnimation>
              <TitleQuote color={Color.White}>
                Software maken voor technisch gecompliceerde processen is ons
                ding. Zo werken we onder andere voor de
                luchtbehandelingsindustrie, die met het produceren van
                energiezuinige luchtbehandeling- en warmteterugwinsystemen een
                belangrijke bijdrage levert aan duurzaamheid en gezondheid in
                kantoren, scholen en vele andere gebouwen.
              </TitleQuote>
            </TypographyAnimation>
          </TitleContainerElement>

          {/* <CtaArrowContainerElement>
            <CtaArrow
              color={Color.Black}
              size={Sizes.large}
              rotation={Rotations.down}
            />
          </CtaArrowContainerElement> */}
        </Section>
      </Grid>
    </ContainerElement>
  )
}
