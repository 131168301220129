import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { ListButton } from "../../../../../components/general/ListButton/ListButton"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { RoutePath } from "../../../../../data/RoutePath"
import { BlueGridTheme, Color } from "../../../../../data/style/variables"
import squareSvg from "../../../../../images/SVG/BlueWhiteLines11.svg"
import dotsSvg from "../../../../../images/SVG/Frame 53 (2).svg"
import {
  ContainerElement,
  DotsContainerElement,
  LinesContainerElement,
  ProductAnimationContainerElement,
  ProductContainerElement,
  ProductsContainerElement,
} from "./styled"

export const ProductsSummary: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      checklist: file(relativePath: { eq: "ChecklistSlides/Overview.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "calc(100vh - 160px)",
          noPaddingBottom: true,
          ...BlueGridTheme,
          background: "transparent",
          spaced: true,
          noPadding: true,
          noOverFlowHidden: true,
          noPaddingMobile: true,
        }}
      >
        <DotsContainerElement>
          <img src={dotsSvg} />
        </DotsContainerElement>
        <LinesContainerElement>
          <img src={squareSvg} />
        </LinesContainerElement>
        <Section
          sectionElementProps={{ justifyCenter: true, alignCenter: true }}
        >
          <ProductsContainerElement>
            <ProductAnimationContainerElement>
              <TypographyAnimation>
                <ProductContainerElement>
                  <ListButton
                    title="Checklist"
                    content="Mobiele applicatie voor het controleren van processen en producten."
                    path={RoutePath.Checklist}
                    color={Color.White}
                  />
                  {/* <ProductImageElement src={data.checklist.childImageSharp.fluid.src} /> */}
                </ProductContainerElement>
              </TypographyAnimation>
            </ProductAnimationContainerElement>
            <ProductAnimationContainerElement>
              <TypographyAnimation index={5}>
                <ProductContainerElement>
                  <ListButton
                    title="Op maat"
                    content="Software volgens uw eisen. Een vaste prijs en uitgebreide intake."
                    path={RoutePath.Custom}
                    color={Color.White}
                  />
                </ProductContainerElement>
              </TypographyAnimation>
            </ProductAnimationContainerElement>
          </ProductsContainerElement>
        </Section>
        <Section
          sectionElementProps={{ justifyCenter: true, alignCenter: true }}
        >
          <ProductsContainerElement>
            <ProductAnimationContainerElement>
              <TypographyAnimation index={1}>
                <ProductContainerElement>
                  <ListButton
                    title="Power Bi oplossingen"
                    content="Cloud en on-premise Power Bi oplossingen voor onze applicaties en uw bestaande applicaties."
                    path={RoutePath.PowerBi}
                    color={Color.White}
                  />
                </ProductContainerElement>
              </TypographyAnimation>
            </ProductAnimationContainerElement>
            <ProductAnimationContainerElement>
              <TypographyAnimation index={4}>
                <ProductContainerElement>
                  <ListButton
                    title="3D CAD oplossingen"
                    content="Op maat gemaakte 3D CAD add-ins voor SolidWorks."
                    path={RoutePath.Cad}
                    color={Color.White}
                  />
                </ProductContainerElement>
              </TypographyAnimation>
            </ProductAnimationContainerElement>
          </ProductsContainerElement>
        </Section>
        <Section
          sectionElementProps={{ justifyCenter: true, alignCenter: true }}
        >
          <ProductsContainerElement>
            <ProductAnimationContainerElement>
              <TypographyAnimation index={2}>
                <ProductContainerElement>
                  <ListButton
                    title="ERP"
                    content="Enterprice resource management systemen voor de industrie."
                    path={RoutePath.Erp}
                    color={Color.White}
                  />
                </ProductContainerElement>
              </TypographyAnimation>
            </ProductAnimationContainerElement>
          </ProductsContainerElement>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
