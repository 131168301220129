import styled from "styled-components/macro"

export const CtaArrowContainerElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  position: absolute;
  bottom: -75px;
  bottom: -145px;
  width: 100%;
  left: 0;
  max-width: 426px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const TitleContainer = styled.div`
  @media (min-width: 769px) {
    width: 150%;
  }
`

export const SpacingContainerElement = styled.div`
  @media (max-width: 1224px) {
    display: none;
  }
`


export const ContainerElement = styled.div`
  display: flex;
  margin: 80px 0 0;
  @media (max-width: 768px) {
    margin: 0;
  }
  z-index: 1;
  position: relative;
`
