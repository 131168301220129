import React from "react";
import { AboutDisc } from './sections/AboutDisc/AboutDisc';
import { Airflow } from './sections/Airflow/Airflow';
import { Clients } from './sections/Clients/Clients';
import { Experience } from './sections/Experience/Experience';
import { Footer } from './sections/Footer/Footer';
import { Hero } from './sections/Hero/Hero';
import Partners from "./sections/Partners";
import { ProductsSummary } from './sections/ProductsSummary/ProductsSummary';
import { Team } from './sections/Team/Team';
import { WorkWithUs } from './sections/WorkWithUs/WorkWithUs';
import { ContainerElement } from "./styled";

export const Home: React.FC = () => {
  return (
    <ContainerElement>
      <Hero />
      <AboutDisc/>
      <ProductsSummary/>
      <WorkWithUs/>
      <Airflow/>
      <Experience/>
      <Clients/>
      <Team/>
      <Partners/>
      <Footer/>
    </ContainerElement>
  );
};
