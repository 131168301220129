import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  display: flex;
`;

export const TitleContainerElement = styled.div`
  z-index: 1;
  padding-left: 25px;

  @media (min-width: 769px) {
    max-width: 80%;
  }
  @media (max-width: 768px) {
    padding: 50px;
  }
`;
