import React from "react"
import { Bold } from "../../../../../components/general/Bold/Bold"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { Typography } from "../../../../../components/general/Typography/Typography"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import { TitleH2 } from "../../../../general/Typography/Typography"
import { ContainerElement, TitleContainerElement } from "./styled"

export const AboutDisc: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "1000px",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
        }}
        detailText={"OVER DISC 1 / 3"}
      >
        <Section>
          <TypographyAnimation>
            <TitleH2 color={Color.Black}>
              U wilt uw bedrijf naar een volgende fase brengen door te
              innoveren? We helpen u daarbij met onze <Bold>tools</Bold>, de
              nieuwste vooruitstrevende technieken en onze solide basis van 25
              jaar ervaring in <Bold>automatisering</Bold> en software.
            </TitleH2>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
