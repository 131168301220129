import React from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { ListButton } from "../../../../../components/general/ListButton/ListButton"
import { Section } from "../../../../../components/general/Section/Section"
import {
  Typography,
  TypographyTypes,
} from "../../../../../components/general/Typography/Typography"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { RoutePath } from "../../../../../data/RoutePath"
import {
  Color,
  FontFamily,
  FontWeight,
  GrayGridTheme,
} from "../../../../../data/style/variables"
import { Bold } from "../../../../general/Bold/Bold"
import { TitleH2, TitleH3 } from "../../../../general/Typography/Typography"
import {
  ButtonContainerElement,
  ContainerElement,
  MobileInfoContainer,
  PageContainerButtonElement,
  TitleContainerElement,
} from "./styled"

export const Experience: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "calc(100vh - 160px)",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
        }}
        detailText={"OVER DISC 3 / 3"}
      >
        <Section>
          <MobileInfoContainer>
            <TitleH2 color={Color.Black}>Onze diensten</TitleH2>
            <TitleH3 color={Color.Black}>
              Inmiddels beschikt Disc over verschillende expertises en bestaan
              we nu <Bold>25 jaar</Bold>. In al deze jaren hebben we veel
              ervaring opgebouwd op de vele aspecten van automatisatie in de
              industrie.
            </TitleH3>
          </MobileInfoContainer>

          <TitleContainerElement>
            <TypographyAnimation>
              <Typography
                fontSize={"13rem"}
                fontSizeMobile={"7rem"}
                lineHeight={"13rem"}
                lineHeightMobile={"normal"}
                fontWeight={FontWeight.Bold}
                fontFamily={FontFamily.Roboto}
                titleType={TypographyTypes.h2}
                color={Color.Black}
              >
                <span>25 Jaar</span>
                <br />
                <span style={{ marginLeft: 100 }}>Ervaring</span>
              </Typography>
            </TypographyAnimation>
          </TitleContainerElement>
        </Section>
        <Section>
          <ButtonContainerElement>
            <TypographyAnimation>
              <PageContainerButtonElement>
                <ListButton
                  title="Web & App"
                  content="Websites en mobiele apps voor diverse doeleinden, op elk moment voor uw afnemers en medewerkers beschikbaar."
                  path={RoutePath.WebAndApps}
                  color={Color.Black}
                />
              </PageContainerButtonElement>
            </TypographyAnimation>
            <TypographyAnimation index={2}>
              <PageContainerButtonElement>
                <ListButton
                  title="Technische software"
                  content="Onze software legt de gebruiksvriendelijke verbinding tussen uw technische product of proces, en uw medewerkers of afnemers."
                  path={RoutePath.TechnicalSoftware}
                  color={Color.Black}
                />
              </PageContainerButtonElement>
            </TypographyAnimation>
          </ButtonContainerElement>
        </Section>
        <Section>
          <ButtonContainerElement>
            <TypographyAnimation index={1}>
              <PageContainerButtonElement>
                <ListButton
                  title="Cloudoplossingen"
                  content="Onze software kan altijd in de cloud. Drie goede redenen om voor de cloud te kiezen."
                  path={RoutePath.Cloud}
                  color={Color.Black}
                />
              </PageContainerButtonElement>
            </TypographyAnimation>
            <div style={{ opacity: 0 }}>
              <TypographyAnimation index={1}>
                <PageContainerButtonElement>
                  <ListButton
                    title="Cloud"
                    content="Onze software draait over het algemeen altijd in de Cloud. Voor Azure, AWS en andere Cloud providers bent u bij ons bij het juiste adres."
                    path={RoutePath.Cloud}
                    color={Color.Black}
                  />
                </PageContainerButtonElement>
              </TypographyAnimation>
            </div>
          </ButtonContainerElement>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
