import { Expo, TimelineLite } from "gsap"
import React, { useRef, useState } from "react"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyTypes } from "../../../../../components/general/Typography/Typography"
import {
  Color,
  FontFamily,
  GrayGridThemeBlackText,
} from "../../../../../data/style/variables"
import christianSvg from "../../../../../images/Illustrations/Team/Christian.svg"
import rudySvg from "../../../../../images/Illustrations/Team/Rudy-mic.svg"
import timSvg from "../../../../../images/Illustrations/Team/Tim.svg"
import yoelSvg from "../../../../../images/Illustrations/Team/Yoel.svg"
import bobSvg from "../../../../../images/Illustrations/Team/Bob.svg"
import youSvg from "../../../../../images/Illustrations/Team/You.svg"
import { Bold } from "../../../../general/Bold/Bold"
import {
  CtaArrow,
  Rotations,
  Sizes,
} from "../../../../general/CtaArrow/CtaArrow"
import {
  Subtitle,
  TitleH2,
  Typography,
} from "../../../../general/Typography/Typography"
import { TypographyAnimation } from "../../../../general/TypographyAnimation/TypographyAnimation"
import {
  ArrowsContainerElement,
  ContainerElement,
  ControlElement,
  ControlNameElement,
  ControlNamesElement,
  ControlsElement,
  SliderSpanElement,
  SpecializedLabelsElement,
  TeamBackgroundBlockElement,
  TeamIllustrationElement,
  TeamMemberContainerElement,
  TeamMemberInformationElement,
  TeamSliderElement,
  TitleContainerElement,
} from "./styled"

type employeeType = {
  name: string
  skills?: string[]
  description: string
  imagePath: string
}

export const Team: React.FC = () => {
  const teamSliderRef = useRef<HTMLDivElement>()
  const timeline = new TimelineLite()
  const [sliderIndex, setSliderIndex] = useState(0)
  const amountOfSlides = 6

  const slides: employeeType[] = [
    {
      name: "Rudy",
      skills: ["Business", "Software development"],
      description:
        "Eigenaar van Disc. Houdt zich het liefst bezig met het in kaart brengen en realiseren van bedrijfsprocessen voor uw software.",
      imagePath: rudySvg,
    },
    {
      name: "Christian",
      skills: ["Design", "Software development"],
      description:
        "Zorgt bij elk project voor een vloeiende en fijne gebruikservaring. Door de laatste design trends en frameworks te gebruiken voor uw applicatie.",
      imagePath: christianSvg,
    },
    {
      name: "Tim",
      skills: ["Technische software", "Software development"],
      description:
        "Werkt het liefst de hele dag met formules en algoritmes. Door slimme software toe te passen in uw software kunnen processen versneld worden.",
      imagePath: timSvg,
    },
    {
      name: "Yoël",
      skills: ["Software development"],
      description:
        "Werkt op dit moment aan het verbeteren van zijn eigen skills en is graag bezig met het maken van technische software.",
      imagePath: yoelSvg,
    },
    {
      name: "Bob",
      skills: ["Hardware", "Software development"],
      description:
        "Heeft een passie voor hardware en software. Bob is op dit moment hard bezig met een IoT platform voor de luchtbehandelingskasten voor één van onze klanten.",
      imagePath: bobSvg,
    },
    {
      name: "Jij?",
      skills: ["Software development"],
      description:
        "Op zoek naar een nieuwe uitdaging? Join ons team door een open-solicatie te sturen naar info@discbv.nl.",
      imagePath: youSvg,
    },
  ]

  const handleSlideClick = (index: number) => {
    timeline.to(teamSliderRef.current, 0.6, {
      xPercent: index * -(100 / amountOfSlides),
      ease: Expo.easeOut,
    })
    setSliderIndex(index)
  }

  const handlePreviousClick = () => {
    if (sliderIndex > 0) handleSlideClick(sliderIndex - 1)
  }

  const handleNextClick = () => {
    if (sliderIndex < amountOfSlides - 1) handleSlideClick(sliderIndex + 1)
  }

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "700px",
          ...GrayGridThemeBlackText,
          detailTextColor: Color.DarkGray,
        }}
        detailText={"HET TEAM 1 / 2"}
      >
        <Section sectionElementProps={{ justifyCenter: true }}>
          <TitleContainerElement>
            <TypographyAnimation>
              <TitleH2 color={Color.Black}>
                Met ons <Bold>multidisciplinair</Bold> team is geen enkel
                project onmogelijk. We houden van <Bold>uidagingen</Bold> en
                projecten die buiten onze comfort-zone liggen.
              </TitleH2>
            </TypographyAnimation>
          </TitleContainerElement>
        </Section>
      </Grid>
      <Grid
        gridElementProps={{
          baseHeight: "1050px",
          ...GrayGridThemeBlackText,
          detailTextColor: Color.DarkGray,
          noPaddingMobile: true,
          noOverFlowHidden: true,
        }}
        detailText={"HET TEAM 2 / 2"}
      >
        <Section sectionElementProps={{ justifyCenter: true, noPadding: true }}>
          <TeamBackgroundBlockElement circle background={Color.WhiteOpague} />
          <TeamSliderElement ref={teamSliderRef}>
            {slides.map((s, idx) => (
              <TeamMemberContainerElement onClick={() => handleSlideClick(idx)}>
                {/* <TeamBackgroundBlockElement circle background={Color.Yellow} /> */}
                <TeamIllustrationElement src={s.imagePath} />
                <TeamMemberInformationElement>
                  <TypographyAnimation reverseOn speed={0.2}>
                    <Typography
                      color={Color.Black}
                      titleType={TypographyTypes.h3}
                      fontSize={"8rem"}
                      fontSizeMobile={"5rem"}
                      fontFamily={FontFamily.CrimsonText}
                    >
                      {s.name}
                    </Typography>
                    <SpecializedLabelsElement>
                      <Typography
                        titleType={TypographyTypes.p}
                        color={Color.Black}
                      >
                        Core skills -{" "}
                        <Bold reverseOn>
                          {s.skills?.map(
                            (skill, idx) =>
                              `${skill} ${
                                idx < s.skills.length - 1 ? " & " : ""
                              }`
                          )}
                        </Bold>
                      </Typography>
                    </SpecializedLabelsElement>
                    <Subtitle color={Color.Black}>{s.description}</Subtitle>
                    {/* <QuestionButtonContainerElement>
                    <ListButton
                      title="Een vraag aan Rudy?"
                      path={""}
                      onlyArrow
                      color={Color.Black}
                    />
                  </QuestionButtonContainerElement> */}
                  </TypographyAnimation>
                </TeamMemberInformationElement>
              </TeamMemberContainerElement>
            ))}
          </TeamSliderElement>
          <ControlsElement>
            <ControlNamesElement>
              {slides.map((s, idx) => (
                <ControlNameElement
                  active={idx === sliderIndex}
                  onClick={() => handleSlideClick(idx)}
                >
                  <img width={"35px"} src={s.imagePath} />
                  {/* <Typography color={Color.Black} titleType={TypographyTypes.a}>
                    {s.name}
                  </Typography> */}
                </ControlNameElement>
              ))}
            </ControlNamesElement>
            <ArrowsContainerElement>
              <SliderSpanElement>{`${
                sliderIndex + 1
              } / ${amountOfSlides}`}</SliderSpanElement>

              <ControlElement onClick={handlePreviousClick}>
                <CtaArrow
                  size={Sizes.medium}
                  rotation={Rotations.left}
                  color={sliderIndex > 0 ? Color.Black : Color.LightGray}
                />
              </ControlElement>

              <ControlElement onClick={handleNextClick}>
                <CtaArrow
                  size={Sizes.medium}
                  rotation={Rotations.right}
                  color={
                    sliderIndex < amountOfSlides - 1
                      ? Color.Black
                      : Color.LightGray
                  }
                />
              </ControlElement>
            </ArrowsContainerElement>
          </ControlsElement>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
