import styled from "styled-components/macro"
import { Color } from "../../../../../data/style/variables"

export const ContainerElement = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProductContainerElement = styled.div`
  margin-bottom: 25px;
`

type TeamMemberInformationElementProps = {
  background: string | Color
  circle?: boolean
}

export const TeamMemberContainerElement = styled.div`
  display: flex;
  @media (min-width: 1240px) {
    margin: 100px;
    padding: 80px;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-top: 75px;
  }
  /* max-width: 1240px; */
  width: 100vw;
  cursor: pointer;
  position: relative;
`
export const TeamBackgroundBlockElement = styled.div<
  TeamMemberInformationElementProps
>`
  background: #EDF1F4;
  // border: ${props => props.background} 15px solid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.background};
  background: rgb(255 255 255 / 44%);
  /* border: #0745af 15px solid; */
  position: absolute;
  top: 0;
  left: 0;
  top: 144px;
  left: 125px;
  border-radius: ${props => (props.circle ? "100%" : 0)};
  width: 460px;
  height: 460px;
  @media (max-width: 768px) {
    padding: 150px;
    width: 360px;
    height: 360px;
    top: -100px;
    right: -75px;
    left: unset;
  }
  @media (max-width: 420px) {
    width: 300px;
    height: 300px;
  }
`

export const TeamMemberInformationElement = styled.div`
  padding: 0 80px;
  @media (max-width: 1240px) {
    padding: 0 30px;
  }
  max-width: 745px;
  width: 100%;
  z-index: 1;
  position: relative;
  z-index: 2;
`

export const TeamSliderElement = styled.div`
  display: flex;
  width: 500vw;
  position: absolute;
  padding-left: 25px;
  @media (max-width: 768px) {
    position: relative;
  }
`

export const TeamIllustrationElement = styled.img`
  @media (min-width: 1240px) {
    margin-left: -150px;
  }
  z-index: 2;
  max-width: 363px;
  max-height: 400px;
  width: 100%;
  @media (max-width: 768px) {
    position: absolute;
    right: 60px;
    top: -125px;
    max-width: 150px;
  }
`

export const QuestionButtonContainerElement = styled.div`
  margin-top: 50px;
`

export const SpecializedLabelsElement = styled.div`
  display: flex;
  font-style: italic;
`

export const ControlsElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  justify-content: center;
  @media (min-width: 769px) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`

export const ArrowsContainerElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`

export const ControlElement = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

export const ControlNamesElement = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  display: none;
`

type ControlNameElementProps = {
  active: boolean
}

export const ControlNameElement = styled.div<ControlNameElementProps>`
  opacity: ${props => (props.active ? 1 : 0.45)};
  padding: 15px;
  z-index: 1;
`
export const TitleContainerElement = styled.div`
  @media (max-width: 768px) {
    padding: 50px;
  }
`

export const SliderSpanElement = styled.span`
  position: absolute;
  top: -30px;
`
