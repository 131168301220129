import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Bold } from "../../../../../components/general/Bold/Bold"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../../data/style/variables"
import { TitleH2 } from "../../../../general/Typography/Typography"
import {
  ContainerElement,
  PartnerContainerElement,
  PartnerElement,
  PartnerLogoElement,
} from "./styled"

export const Partners: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      datalogic: file(relativePath: { eq: "Partners/datalogic.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zebra: file(relativePath: { eq: "Partners/zebra.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hp: file(relativePath: { eq: "Partners/hp.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      microsoft: file(relativePath: { eq: "Partners/microsoft.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "675px",
          ...GrayGridTheme,
          spaced: true,
          largeInfoPadding: true,
          // noPaddingBottom: true,
        }}
        title={
          <TitleH2 color={Color.Black}>
            Onze <Bold>partners</Bold>
          </TitleH2>
        }
        subTitle={
          "In samenwerking met onze partners kunnen wij u naast onze software producten ook voorzien van de juiste bijbehorende hardware."
        }
      >
        <Section sectionElementProps={{ noPadding: true }}>
          <TypographyAnimation>
            <PartnerContainerElement>
              <PartnerElement href="https://www.datalogic.com/" target="_blank">
                <PartnerLogoElement
                  src={data.datalogic.childImageSharp.fluid.src}
                />
              </PartnerElement>
              <PartnerElement
                href="https://www.microsoft.com/nl-nl"
                target="_blank"
              >
                <PartnerLogoElement
                  src={data.microsoft.childImageSharp.fluid.src}
                />
              </PartnerElement>
            </PartnerContainerElement>
          </TypographyAnimation>
        </Section>
        <Section sectionElementProps={{ noPadding: true }}>
          <TypographyAnimation>
            <PartnerContainerElement>
              <PartnerElement
                href="https://www.zebra.com/gb/en/products.html"
                target="_blank"
              >
                <PartnerLogoElement
                  src={data.zebra.childImageSharp.fluid.src}
                />
              </PartnerElement>
            </PartnerContainerElement>
          </TypographyAnimation>
        </Section>
        <Section sectionElementProps={{ noPadding: true }}>
          <TypographyAnimation>
            <PartnerContainerElement>
              <PartnerElement href="https://store.hp.com/" target="_blank">
                <PartnerLogoElement src={data.hp.childImageSharp.fluid.src} />
              </PartnerElement>
            </PartnerContainerElement>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
