import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  display: flex;
`;

export const TitleContainerElement = styled.div`
  z-index: 1;
  padding-left: 25px;
  @media (min-width: 769px) {
    max-width: 80%;
  }
`;

export const TrianglesElement = styled.img`
  margin-top: 105px;
  z-index: 1;
  max-height: 600px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ListItemElement = styled.div`
  margin-bottom: 50px;
  border-bottom: 1px solid #D6D9DC;
`;
