import styled from "styled-components/macro"
import { BlueGridTheme, Color } from "../../../../../data/style/variables"

export const ContainerElement = styled.div`
  display: flex;
  height: 100%;
  @media (max-width: 768px) {
    background: transparent;
  }
  background: ${Color.Primary};
`

export const ProductContainerElement = styled.div`
  margin-bottom: 100px;
  background: ${BlueGridTheme.background};
  @media (max-width: 768px) {
    margin: 5px 0;
    width: 100%;
    height: 185px;
  }
  border-radius: 7px;
  padding: 15px;
  position: relative;
  overflow: hidden;
`

export const ProductsContainerElement = styled.div`
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`

export const ProductImageElement = styled.img`
  position: absolute;
  width: 80px;
  bottom: -46px;
  right: -12px;
`

export const DotsContainerElement = styled.div`
  position: absolute;
  top: -124px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`

export const LinesContainerElement = styled.div`
  bottom: -151px;
  top: unset;
  z-index: 9;
  right: 138px;
  width: 138px;
  overflow: hidden;
  height: 302px;
  position: absolute;
  transform: rotate(180deg);
  @media (max-width: 768px) {
    display: none;
  }
`

export const ProductAnimationContainerElement = styled.div`
  @media (max-width: 768px) {
    margin: 5px;
    width: 100%;
  }
`
