import styled from "styled-components/macro"

export const ClientContainerElement = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 75px;
  @media (max-width: 768px) {
    margin: 0;
    flex-direction: row;
  }
`

export const ContainerElement = styled.div`
  display: flex;
`

export const TitleContainerElement = styled.div`
  @media (max-width: 768px) {
    padding: 50px;
  }
`

export const ClientElement = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 125px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
`

export const ClientLogoElement = styled.img`
  width: 120px;
  @media (max-width: 768px) {
    width: 75px;
  }
`

export const ArrowContainerElement = styled.div``
