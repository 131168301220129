import styled from "styled-components/macro"

export const ContainerElement = styled.div`
  display: flex;
`

export const ImageContainerElement = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const ImageElement = styled.img`
  width: 100%;
  max-width: 720px;
  z-index: 1;
  position: relative;
  box-shadow: 5px 6px 30px rgba(0, 0, 0, 0.2);
  z-index: 3;
`

export const TitleElement = styled.div`
  /* position: absolute; */
  left: 75px;
  top: 250px;
  max-width: 750px;
  width: 100%;
  z-index: 4;
`

export const ImageHolderElement = styled.div`
  position: relative;
`

export const SquareElement = styled.div`
  background: transparent;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50px;
  left: 50px;
`
export const WavePatternElement = styled.img`
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 50px;
  bottom: -50px;
`

export const TitleContainerElement = styled.div`
  @media (max-width: 768px) {
    padding: 50px;
  }
`

export const CtaArrowContainerElement = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: -150px;
  position: absolute;
  z-index: 1;
`
