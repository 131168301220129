import React from "react"
import { Bold } from "../../../../../components/general/Bold/Bold"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import TypographyAnimation from "../../../../../components/general/TypographyAnimation"
import {
  Color,
  FontFamily,
  FontWeight,
  GrayGridTheme,
} from "../../../../../data/style/variables"
import trianglesSvg from "../../../../../images/SVG/Frame 59.svg"
import {
  TitleH2,
  TitleH3,
  Typography,
  TypographyTypes,
} from "../../../../general/Typography/Typography"
import { ContainerElement, ListItemElement, TrianglesElement } from "./styled"

export const WorkWithUs: React.FC = () => {
  const Title = () => {
    return (
      <>
        <TitleH2 color={Color.Black}>
          Waarom zou je bij jouw volgende <Bold>project</Bold> voor Disc moeten
          kiezen?
        </TitleH2>
        {/* <img src="./Patterns/WW.svg" /> */}
      </>
    )
  }

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "1000px",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
          noOverFlowHidden: true,
        }}
        title={<Title />}
        detailText={"OVER DISC 1 / 3"}
      >
        <Section>
          <TypographyAnimation>
            <ListItemElement>
              <Typography
                titleType={TypographyTypes.p}
                fontWeight={FontWeight.Light}
                fontFamily={FontFamily.RobotoMono}
                fontSize={"1.4rem"}
                color={Color.Black}
              >
                - Automatiseren
              </Typography>
              <TitleH3 color={Color.Black}>
                Uw bedrijfsprocessen op <Bold>volle toeren</Bold> laten draaien
                is wat ons drijft. Disc is gespecialiseerd in het automatiseren
                van taken en processen.
              </TitleH3>
            </ListItemElement>
          </TypographyAnimation>
        </Section>
        <Section>
          <ListItemElement>
            <Typography
              titleType={TypographyTypes.p}
              fontWeight={FontWeight.Light}
              fontFamily={FontFamily.RobotoMono}
              fontSize={"1.4rem"}
              color={Color.Black}
            >
              - Service en klantvriendelijkheid
            </Typography>
            <TitleH3 color={Color.Black}>
              Nauw contact met de klant staat bij ons hoog in het vaandel. We
              zijn <Bold>24/7 bereikbaar</Bold> voor vragen en service.{" "}
            </TitleH3>
          </ListItemElement>
        </Section>
        <Section>
          <TrianglesElement src={trianglesSvg} />
        </Section>
      </Grid>
    </ContainerElement>
  )
}
