import React from "react"
import { Home } from "../components/pages/Home/Home"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      description="U wilt uw bedrijf naar een volgende fase brengen door te innoveren ? Met onze tools en software met de nieuwste vooruitstrevende technieken helpen we u daarbij."
      title="Automatisering & Software"
      lang="nl"
    />
    <Home />
  </Layout>
)

export default IndexPage
