import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Bold } from "../../../../../components/general/Bold/Bold"
import { Grid } from "../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, YellowGridTheme } from "../../../../../data/style/variables"
import { TitleH2 } from "../../../../general/Typography/Typography"
import {
  ClientContainerElement,
  ClientElement,
  ClientLogoElement,
  ContainerElement,
} from "./styled"

export const Clients: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      radio: file(relativePath: { eq: "Logos/LogoBlack/538.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      metal: file(relativePath: { eq: "shutterstock_468738614.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brink: file(relativePath: { eq: "Logos/LogoBlack/brink.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nedair: file(relativePath: { eq: "Logos/LogoBlack/nedair.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ntp: file(relativePath: { eq: "Logos/LogoBlack/ntp.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wolf: file(relativePath: { eq: "Logos/LogoBlack/wolf2.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eva: file(relativePath: { eq: "Logos/LogoBlack/eva3.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      holmak: file(relativePath: { eq: "Logos/LogoBlack/holmak.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rnw: file(relativePath: { eq: "Logos/LogoBlack/rnw.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ing: file(relativePath: { eq: "Logos/LogoBlack/ing.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "1000px",
          ...YellowGridTheme,
          spaced: true,
          largeInfoPadding: true,
          noPaddingBottom: true,
          // backgroundImage:
          //   "linear-gradient(rgba(0, 0, 0, 0), #0000009e), url(./sol-tZw3fcjUIpM-unsplash.jpg) no-repeat center center/cover",
        }}
        title={
          <TitleH2 color={Color.Black}>
            Wij werken voor <Bold>grote</Bold> en <Bold>kleine</Bold> bedrijven
          </TitleH2>
        }
        subTitle={
          "We helpen grote én kleine bedrijven met verschillende software oplossingen. Onze oplossingen zijn gemakkelijk op te schalen naar de grootte van uw bedrijf. Hieronder een paar van onze geweldige klanten."
        }
      >
        <Section sectionElementProps={{ noPadding: true }}>
          <TypographyAnimation>
            <ClientContainerElement>
              <ClientElement href="https://nedair.nl" target="_blank">
                <ClientLogoElement
                  src={data.nedair.childImageSharp.fluid.src}
                />
              </ClientElement>
              <ClientElement
                href="https://www.brinkclimatesystems.nl/"
                target="_blank"
              >
                <ClientLogoElement src={data.brink.childImageSharp.fluid.src} />
              </ClientElement>
              <ClientElement href="https://www.holmak.eu/" target="_blank">
                <ClientLogoElement
                  src={data.holmak.childImageSharp.fluid.src}
                />
              </ClientElement>
            </ClientContainerElement>
          </TypographyAnimation>
        </Section>
        <Section sectionElementProps={{ noPadding: true }}>
          <TypographyAnimation>
            <ClientContainerElement>
              <ClientElement href="https://www.ntp.dk/" target="_blank">
                <ClientLogoElement src={data.ntp.childImageSharp.fluid.src} />
              </ClientElement>
              <ClientElement href="https://www.wolf.eu/" target="_blank">
                <ClientLogoElement src={data.wolf.childImageSharp.fluid.src} />
              </ClientElement>
              <ClientElement href="https://www.rnw.org/" target="_blank">
                <ClientLogoElement src={data.rnw.childImageSharp.fluid.src} />
              </ClientElement>
            </ClientContainerElement>
          </TypographyAnimation>
        </Section>
        <Section sectionElementProps={{ noPadding: true }}>
          <TypographyAnimation>
            <ClientContainerElement>
              <ClientElement href="https://www.538.nl/" target="_blank">
                <ClientLogoElement src={data.radio.childImageSharp.fluid.src} />
              </ClientElement>
              <ClientElement href="https://evaoptic.nl/nl/" target="_blank">
                <ClientLogoElement src={data.eva.childImageSharp.fluid.src} />
              </ClientElement>
              <ClientElement href="https://www.ing.nl/" target="_blank">
                <ClientLogoElement src={data.ing.childImageSharp.fluid.src} />
                {/* <ListButton color={Color.Black} path={'/'}></ListButton> */}
              </ClientElement>
            </ClientContainerElement>
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
